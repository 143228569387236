import Box from "@mui/system/Box";
import Container from "@mui/system/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const description = "Accesso in corso...";

export default function LoginLanding() {
  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        flexDirection={"column"}
      >
        <CircularProgress />
        <Box height={10} />
        <Typography fontFamily={"Nunito"}>{description}</Typography>
      </Box>
    </Container>
  );
}
